
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheTopNavBar',
  auth: true,
  computed: {
    ...mapGetters(['userFirstName']),
    currentRouteName() {
      return this.$route?.name?.split('__').shift();
    },
    currentPageHeader() {
      switch (this.currentRouteName) {
        case 'dashboard':
          return `${this.$t('welcome_back')}, ${this.userFirstName}`;
        case 'routes':
          return this.$t('routes_overview');
        case 'vehicles':
          return this.$t('vehicles_overview');
        case 'drivers':
          return this.$t('drivers_overview');
        case 'places-garages':
          return this.$t('garages_overview');
        case 'places-depots':
          return this.$t('depots_overview');
        default:
          return '';
      }
    },
    isOrganizationsPage() {
      switch (this.currentRouteName) {
        case 'organisations':
        case 'organisations-sub-organisations':
          return true;
        default:
          return false;
      }
    },
  },
  methods: {
    ...mapActions({
      toggleSidebar: 'app/toggleSidebar',
    }),
  },
};
